import { gql } from 'graphql-request'
import { IDefaultValue } from './interface'

export const DEFAULT_VALUE: IDefaultValue = {
  loadings: {
    organizations: false,
    roles: false,
  },
  roles: [],
  organizations: [],
  currentOrganization: {
    organizationid: '',
    name: '',
  },
  handleCurrent: () => {},
}

export const QUERY_GET_ORGANIZATIONS = gql`
  query Organization($userid: String!) {
    getOrganizationsByUserid(userid: $userid) {
      org_organizationid
      org_name
      org_email
      org_img
      org_phone
      org_phone_ext
    }
  }
`

export const QUERY_GET_USER_ROLES = gql`
  query Organization(
    $organizationid: ID!
    $userid: ID!
    $page: Int
    $perpage: Int
  ) {
    getUserRolesByOrganization(
      organizationid: $organizationid
      userid: $userid
      page: $page
      perpage: $perpage
    ) {
      records {
        roleid
        category
        slug
        name
      }
    }
  }
`
