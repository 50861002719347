import { Dispatch, Reducer } from 'redux'
import { IState } from './types'
import { GET_LANG } from './constants'

const initialState: IState = {
  lang: 'es',
}

const reducer: Reducer<IState, any> = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANG:
      return { ...state, lang: action.payload }
    default:
      return state
  }
}

export const fetchLang =
  (params): any =>
  (dispatch: Dispatch): any =>
    dispatch({ type: GET_LANG, payload: params?.lang })

export default reducer
