import axios from 'axios'

export const getToken = async () => {
  try {
    const link = `${process.env.NEXTAUTH_URL}/api/authenticate`
    const resp = await fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await resp.text()
    return `${data}.${process.env.ASD_APIKEY}`
  } catch (err) {
    console.log(err)
    return null
  }
}
