// import HttpBackend from 'i18next-http-backend'

const supportedLngs = ['es', 'en']

export const ni18nConfig = {
  fallbackLng: supportedLngs,
  supportedLngs,
  ns: [
    'common',
    'page-home',
    'page-edit-event',
    'page-list-event',
    'page-event-convocatory',
    'page-new-event',
    'page-login',
    'page-event-inscription',
    'page-event-coupon',
    'page-new-edit-org',
    'page-followup',
    'page-new-edit-carousel',
    'page-list-organization',
    'page-add-edit-users',
    'page-stage',
    'page-new-edit-user',
    'page-list-users',
    'page-tyr',
    'page-inscriptions',
    'page-event-benefit',
    'page-serial',
    'page-event-sponsor',
    'page-delivery',
    'page-fmtri-types',
    'page-fmtri-list',
    'page-fmtri-create-edit',
    'page-event-tshirt',
    'page-certificate',
    'page-download-center',
    'page-promotion',
    'page-merge',
    'page-notifications',
    'page-inbody',
  ],
}
