import { createContext, useEffect, useState } from 'react'
import { useSession } from 'next-auth/react'
import { IOrganization } from '@asdeporte/apolo-components/cjs/_common/js/dataType'
import { ORGANIZATION_GRAPHQL } from '@utils/constants'
import {
  DEFAULT_VALUE,
  QUERY_GET_ORGANIZATIONS,
  QUERY_GET_USER_ROLES,
} from './constant'
import { IUserRol, QueryGetOrganizations, QueryGetUserRoles } from './interface'
import { useRouter } from 'next/router'

export const OrganizationContext = createContext(DEFAULT_VALUE)

export const OrganizationProvider = ({ children }) => {
  const router = useRouter()
  const { data: session, status, update: sessionUpdate } = useSession()
  const [loadings, setLoadings] = useState({
    organizations: false,
    roles: false,
  })
  const [roles, setRoles] = useState<IUserRol[]>([])
  const [currentOrganization, setCurrentOrganization] =
    useState<IOrganization>()
  const [organizations, setOrganizations] = useState<IOrganization[]>([])

  const handleCurrent = async (currOrg: IOrganization) => {
    try {
      const updatedSession = {
        ...session,
        org: currOrg,
      }
      setCurrentOrganization(currOrg)
      await sessionUpdate(updatedSession)
      router.push(`/`)
    } catch (error) {}
  }

  // GET ORGANIZATIONS AND PRESELECT FIRST ONE
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        setLoadings({ ...loadings, organizations: true })

        if (status === 'loading') return

        if (status === 'unauthenticated') {
          setLoadings({ ...loadings, organizations: false })
        }

        const { getOrganizationsByUserid } =
          await ORGANIZATION_GRAPHQL.request<QueryGetOrganizations>(
            QUERY_GET_ORGANIZATIONS,
            {
              userid: session.user.asdeporteId,
            }
          )

        const current = getOrganizationsByUserid.length
          ? getOrganizationsByUserid[0]
          : null

        setOrganizations(
          getOrganizationsByUserid.map(org => ({
            organizationid: org.org_organizationid,
            name: org.org_name,
            email: org.org_email,
            img: org.org_img,
            phone: org.org_phone,
            phone_ext: org.org_phone_ext,
          }))
        )

        session?.org
          ? setCurrentOrganization(session?.org)
          : setCurrentOrganization(
              current
                ? {
                    organizationid: current.org_organizationid,
                    name: current.org_name,
                    email: current.org_email,
                    img: current.org_img,
                    phone: current.org_phone,
                    phone_ext: current.org_phone_ext,
                  }
                : null
            )

        setLoadings({ ...loadings, organizations: false })
      } catch (error) {
        setLoadings({ ...loadings, organizations: false })
      }
    }

    fetchOrganizations()
  }, [session?.user?.asdeporteId, status])

  // GET USER ROLES BY ORGANIZATION
  useEffect(() => {
    const init = async () => {
      try {
        if (!currentOrganization) return

        setLoadings({ ...loadings, roles: true })

        if (status === 'loading') return

        if (status === 'unauthenticated') {
          setLoadings({ ...loadings, roles: false })
        }

        const { getUserRolesByOrganization } =
          await ORGANIZATION_GRAPHQL.request<QueryGetUserRoles>(
            QUERY_GET_USER_ROLES,
            {
              organizationid: currentOrganization.organizationid,
              userid: session.user.asdeporteId,
              page: 1,
              perpage: 500,
            }
          )

        setRoles(getUserRolesByOrganization.records)
        setLoadings({ ...loadings, roles: false })
      } catch (error) {
        setLoadings({ ...loadings, roles: false })
      }
    }
    init()
  }, [session?.user?.asdeporteId, status, currentOrganization])

  return (
    <OrganizationContext.Provider
      value={{
        loadings,
        roles,
        organizations,
        currentOrganization,
        handleCurrent,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
